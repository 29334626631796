$important: unquote('!important');
$white: rgba(255, 255, 255, 1);

@import '../global/breakpoints';

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
) !default;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px
) !default;

//
// Container
//
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}

@each $name, $width in $grid-breakpoints {
  @include media-breakpoint-up($name) {
    .container {
      max-width: #{map-get($container-max-widths, $name)};
    }
  }
}


//
// Row
//
.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;
}

//
// Column
//
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  width: 100%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@include media-breakpoint-up(lg) {
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 percentage(1 / 3);
    max-width: percentage(1 / 3);
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}

//
// Padding
//
.p-0 {
  padding: 0 $important;
}

@include media-breakpoint-up(md) {
  .p-md-0 {
    padding: 0 $important;
  }
}

@include media-breakpoint-up(lg) {
  .pr-lg-3, .px-lg-3 {
    padding-right: 16px $important;
  }
}

figure.article-banner-media {
  margin-bottom: 0;
  margin-right: 16px;
  margin-top: 16px;
  padding: 0;
}

.ie-eight {
  display: block;
}

//.lazy,
//.article-banner {
//  display: none;
//}

.article-item {
  float: left;
  margin-right: 16px;
  max-width: 30%;
  min-height: 450px;

  .archive-image {
    min-height: 190px;
  }
}

.more-posts-wrap {
  clear: both;
  display: none;
  margin-left: auto;
  margin-right: auto;
  max-width: 150px;

  .button-label {
    padding: 16px;
  }
}

.page .pagination-container {
  clip-path: none;
  clip: auto $important;
  display: block;
  height: 20px;
  line-height: normal;
  margin: 16px auto;
  padding: 15px 23px 14px;
  position: relative;
  text-decoration: none;
  width: 250px;
}

.tags-n-share {
  clear: both;
  margin-top: 16px;
}

//
// Header
//
.logo-citylife .content {
  background: transparent url('/wp-content/themes/citytheme/img/citylife.png') no-repeat 0 0;
}

.logo-citylife.red .content {
  background: transparent url('/wp-content/themes/citytheme/img/citylife.png') no-repeat 0 0;
}

.logo-citynews .content {
  background: transparent url('/wp-content/themes/citytheme/img/citynews.png') no-repeat 0 0;
}

.logo-citynow .content {
  background: transparent url('/wp-content/themes/citytheme/img/citynow.png') no-repeat 0 0;
}

.logo-citygroup .content {
  background: transparent url('/wp-content/themes/citytheme/img/citygroup.png') no-repeat 0 0;
}

.logo-spoon-n-fork .content {
  background: transparent url('/wp-content/themes/citytheme/img/spoon.png') no-repeat 0 0;
}
